import Img1 from '../../images/barber-shop.png'
import Img2 from '../../images/barber-shop-2.png'

const Loading = () =>{

    return(
        <div className="load">
                <img src={Img1} alt="" className="loading1"/>
                <img src={Img2} alt="" className="loading2"/>
        </div>
    )
}

export default Loading