import React, {useState, useEffect} from 'react'
import axios from 'axios'

import './style.css'
import Loading from './engine/logo.jsx'

import Image from '../images/IMG_3191.jpeg'
import Image1 from '../images/contact_spruce.png'

const Gallery = () =>{

    const [errors, setError] = useState("")
    const [images, setImages] = useState([])
    const [img, setImg] = useState(1)
    const [loaded, setLoad] = useState("")
  
      const front = async(e) => {

          e.preventDefault()
          setLoad("")
          const res = await get_img(images[images.length-1]["_id"])
          setLoad(res[0]["item"]["image"])
          return null
      }

      const back = async(e) =>{

          e.preventDefault()
          setLoad("")
          const res = await get_img(images[0]["_id"])
          setLoad(res[0]["item"]["image"])
          return null
      }

      const f_ward = async(e) => {

          try {
            e.preventDefault()
            if(images.length === 0){
                return null
            }
            let copy = img
            if(copy < images.length){
                copy = copy + 1
            }
            setLoad("")
            setImg(copy)
            const res = await get_img(images[copy-1]["_id"])
            setLoad(res[0]["item"]["image"])
            
          } catch (error) {
            
          }
      
      }

      const b_ward = async(e) => {
        try {
          e.preventDefault()
          if(images.length === 0){
              return null
          }
          let copy = img
          if(copy > 1){
              copy = copy - 1
          }
          setLoad("")
          setImg(copy)
          const res = await get_img(images[copy-1]["_id"])
          setLoad(res[0]["item"]["image"])
          
        } catch (error) {
          
        }
      }

    useEffect(()=>{

      get_imgs()

    },[])
  
    const get_imgs = async() =>{
       try{

          const link = process.env.REACT_APP_K
          const res = await axios.post(`${link}${process.env.REACT_APP_KZ}`,{

            k4 : process.env.REACT_APP_KL,
            k6 : process.env.REACT_APP_KO
          })
          if(!res.request){
            console.log("fail")
            return null
          }
          const image = res.data.item
          setImages(image)
          const num = img
          const selection = image[num-1]["_id"]
          const finish = await get_img(selection)
          setLoad(finish[0]["item"]["image"])
  
       }catch(err){
          setError("Error retrieving gallery.")
       }

    }
  
      const get_img = async(id) => {

        try{

            const link = process.env.REACT_APP_K
            const res = await axios.post(`${link}${process.env.REACT_APP_KM}`,{
              content : id,
              k4 : process.env.REACT_APP_KL,
              k6 : process.env.REACT_APP_KO
            })
            if(!res.request){
              setError("Failed retrieving image.")
              return null
            }
            return res.data.item

        }catch(err){
            setError("Failed retrieving image.")
      
        }
      }
  return(
      <div>
            <div className="">
                    <h3 className="slogan">For best service, call for appointment</h3>
                    <img src={Image} alt="" className="g_image1"/>
                    <a className="button_container" href="tel:+17577134879"> 
                      <div >
                        <h3>
                          <img className="img1" src={Image1} alt="Sorry, image could not be loaded" /> 
                          Click here
                        </h3>
                      </div>
                    </a>
                    <div className="gallery">
                        {
                        loaded?
                          <div className="img_container">
                            <img className="img" src={loaded} alt="Sorry, image could not be loaded" />
                            <div className="gallery_buttons">
                                <button className="b_ward btn" onClick={(e)=>{setImg(1); back(e)}}> {"<<<"} </button>
                                <button className="b_ward btn" onClick={(e)=>{b_ward(e);}}> {"<"} </button>
                                <p className="c_img"> {`${img}`} / {`${images.length}`} </p>
                                <button className="f_ward btn" onClick={(e)=>{f_ward(e)}}> {">"} </button>
                                <button className="f_ward btn" onClick={(e)=>{setImg(images.length); front(e)}}> {">>>"} </button>
                            </div>
                          </div>
                        :   <div className="img_container">
                                <Loading/>
                            </div>
                        }
                    </div>
                </div>
        </div>
  )
}
export default Gallery