import React from 'react'

import Nav from '../nav.jsx'
import Footer from '../footer.jsx'

const Terms = () =>{
    return(
        <div>
                <div className="terms">
                <h1>SITE USAGE POLICY</h1>

                <h2>1. Introduction</h2>
                Welcome to the web application of Spruce's Cutting Corner LLC ("the Barbershop"). This Site Usage Policy governs your use of our web application, including the gallery section. By accessing or using our web application, you agree to comply with this policy.

                2. Ownership and Copyright
                All content, including text, images, graphics, logos, and videos, displayed on the web application are the property of Spruce's Cutting Corner LLC or its content suppliers and are protected by applicable copyright laws. Unauthorized use of any content from this web application may violate copyright laws.

                <h2>3. Use of Images</h2>
                a. The gallery section of our web application contains images showcasing our barbershop's work, including haircuts and hairstyles.
                b. All images used in the gallery section are the property of Spruces Cutting Corner LLC.
                c. You may view and access the images in the gallery for personal, non-commercial use only.
                d. You may not download, copy, reproduce, distribute, or modify any images from the gallery without prior written permission from Spruces Cutting Corner LLC.

                <h2>4. Image Accreditation</h2>
                a. If you wish to use any images from our gallery for any purpose other than personal viewing, you must obtain written permission from Spruces Cutting Corner LLC.
                b. When using any images from our gallery with permission, you must provide proper accreditation by including the following "Image courtesy of Spruces Cutting Corner LLC".
                </div>
        </div>
    )
}
export default Terms