import Nav from './nav.jsx'
import Footer from './footer.jsx'

const Info = () =>{
    
    return(
        <div className="contact_container">
            <div className="info">
                <br />
                <h2>Spruce's Cutting Corner</h2>
                <br />
                <div>
                    <address>   1425 25th St <br />
                                Newport News, VA, 23607
                    </address>
                <br />
                    <p>(757)713-4879</p>
                </div>
                <br />
               <div className="business_hours">
                    <h3>Shop Hours</h3>
                    <ul >
                        <div>Tuesday 09:00 - 18:00</div>
                        <div>Wednesday 09:00 - 18:00</div>
                        <div>Thursday 09:00 - 18:00</div>
                        <div>Friday 09:00 - 18:00</div>
                        <div>Saturday 09:00 - 18:00</div>
                        <div>Sunday Closed</div>
                        <div>Monday Closed</div>
                    </ul>
               </div>
            </div>
        </div>
    )
}
export default Info