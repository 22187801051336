import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Nav from '../nav.jsx'
import Footer from '../footer.jsx'
import Image from '../../images/IMG_3242.jpeg'
import Image1 from '../../images/IMG_3240.jpeg'
import Image2 from '../../images/IMG_4444.jpg'
import Image3 from '../../images/IMG_3542.jpeg'
import Image4 from '../../images/IMG_245353.jpeg'
import Image5 from '../../images/IMG_5555.jpeg'
import './style.css'
const Services = () =>{

    const [error, setError] = useState("")
    const [data, setData] = useState([])
    const [choice, setChoice] = useState(0)

    const [fName, setFName] = useState("")
    const [lName, setLName] = useState("")
    const [eMail, setEMail] = useState("")
    const [comment, setComment] = useState("")

    const link = process.env.REACT_APP_K
    const key =  process.env.REACT_APP_KJ
    const key2 = process.env.REACT_APP_KE
    const changeChoice = (e) =>{
        e.preventDefault()
        if(choice === ""){
            setChoice(0)
        }else{
            setChoice("")
        }
    }

    const retrieveComments = async() => {
        try{

            const content = [process.env.REACT_APP_KL, process.env.REACT_APP_S, process.env.REACT_APP_KO]
            const res = await axios.post(`${link}${key}`,{
                content
            })
                if(!res){
                    console.log("false")
                    setError("Error retrieving images, please try again later")
                    return null
                }
                console.log(res.data.response)
                setError("")
                setData(res.data.response)
        }catch(err){
            console.log(err)
            setError("Error retrieving comments.")
            return null
        }
    }

    const createComment = async(e) =>{
        e.preventDefault()
        const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
        if(comment.length > 500 || !validEmail.test(eMail) || fName.length > 20 || lName.length > 20){
            setError("Please check values.")
            return null
        }
        try{

            const content = await [process.env.REACT_APP_KL, process.env.REACT_APP_S, process.env.REACT_APP_KO]
            const data = {fName,lName,eMail,comment}
            const res = await axios.post(`${link}${key2}`,{
                content,
                data
            })
                if(!res){
                    setError("Error creating comment, please try again later")
                    return null
                }
                setError("Comment submited for review!")
                setLName("")
                setFName("")
                setEMail("")
                setComment("")
        }catch(err){
            console.log(err)
            setError("Error creating comment.")
            return null
        }
    }
     
    useEffect(()=>{
        retrieveComments()
    },[])
    return(
        <div>
                <div className="services_Container">
                    <h2>Services Provided</h2>    
                    <br />                
                    <div className="title"> <img src={Image4} alt="" className="image2"/><span className="title2">Hairwash</span></div>
                    <div className="title"><img src={Image3} alt="" className="image2"/> <span className="title2">Varied Barber Services</span></div>                    
                    <div className="title"><img src={Image} alt="" className="image2"/><span className="title2">Razor Shaves</span></div>
                    <div className="title"><img src={Image1} alt=""  className="image2"/><span className="title2">Facial</span></div>
                    
                    <button className="before_after" onClick={(e)=>changeChoice(e)}>
                    {
                        choice === 0?
                        <div className="title"><img src={Image2} alt=""  className="image4"/><span className="title2">Loc Mx Before</span></div>
                        : <div className="title"><img src={Image5} alt=""  className="image4"/><span className="title2">Loc Mx After</span></div>
                        }
                        <p className="click">Click me</p>
                    </button>
                </div>

                <div className="customer_endorsement">Customer Testimonials</div>
                <div className="testimonials">       
                   {
                    data.map((item, id)=>{
                        return(
                            <div key={id}>
                                <div className="comment">
                                    <div className="comment_content">
                                        <p>
                                            {item.item.comment}
                                        </p>
                                    </div>
                                    <div className="author_name">
                                            {item.item.name}
                                    </div>
                                    <div className="post_date">
                                        {item.createdAt.slice(0,10)}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                   }
                </div>

                <div className="customer_endorsement">Submit Testimonials</div>
                <div className="customer_create">
                    
                    <div className="comment_box">
                        <textarea name="" id="" cols="40" rows="10" onChange={(e)=>{setComment(e.target.value)}} value={comment}></textarea>
                    </div>
                    <div className="name_input">
                        <input type="text" placeholder="First name" onChange={(e)=>{setFName(e.target.value)}} value={fName}/>
                        <input type="text" placeholder="Last name" onChange={(e)=>{setLName(e.target.value)}} value={lName}/>
                        <input type="text" placeholder="E-mail" onChange={(e)=>{setEMail(e.target.value)}} value={eMail}/>
                    </div>
                    <div>
                        <div className="error">
                            {error}
                        </div>
                        <button className="submit_btn" onClick={(e)=>{createComment(e)}}>Submit</button>
                    </div>
                </div>  

        </div>
    )
}

export default Services