import React from 'react'
import Nav from '../nav.jsx'
import Footer from '../footer.jsx'

const Privacy = () =>{

    return(
        <div>
                <div className="privacy">
                        <h1>Privacy Policy</h1>
                        At Spruce's Cutting Corner, we are committed to protecting the privacy of our customers and users of our web application. This privacy policy (the "Policy") explains how we collect, use, and protect the information we receive from you when you use our web application.

                        <h2>Information We Collect</h2>

                        We collect two types of information: personal information and non-personal information.

                        * Personal Information: We collect personal information that you voluntarily provide to us, such as your name, email address, and phone number. We also collect information about your interactions with our web application, such as the pages you visit and the features you use.
                        * Non-Personal Information: We collect non-personal information about your device and your use of our web application, such as your IP address, browser type, and operating system. We also collect information about your location and the pages you visit.

                        <h2>How We Use Your Information</h2>

                        We use your personal information to provide and improve our web application, to communicate with you, and to fulfill your requests. We also use your non-personal information to improve our web application and to provide you with a better user experience.

                        * Communication: We use your email address and phone number to communicate with you about your account, to send you updates and promotional messages, and to respond to your inquiries.
                        * Fulfillment: We use your personal information to fulfill your requests, such as to process your orders and to provide you with the services you request.
                        * Improvement: We use your non-personal information to improve our web application, to personalize your experience, and to provide you with relevant content and offers.

                        <h2>Accreditation of Images</h2>

                        We use images in our web application to provide you with a better user experience. We accredit all images used in our web application to Spruces Cutting Corner LLC.

                       <h2> Information Sharing</h2>

                        We do not share your personal information with third parties, except as required by law or to fulfill your requests. We may share your non-personal information with third parties, such as to improve our web application and to provide you with relevant content and offers.

                        <h2>Data Security</h2>

                        We take reasonable measures to protect your information from unauthorized access, use, and disclosure. We use secure servers and encryption to protect your personal information, and we restrict access to your information to authorized personnel only.

                        <h2>Data Security</h2>

                        We retain your information for as long as necessary to provide you with our services and to fulfill our legal obligations. We may also retain your information for a longer period of time if we have a legitimate business reason to do so.

                        <h2>Your Rights</h2>

                        You have the right to access, correct, and delete your personal information. You can exercise these rights by contacting us at contact@saad.services.

                        <h2>Changes to This Policy</h2>

                        We may update this Policy from time to time. We will notify you of any changes by posting the updated Policy on our web application.

                        <h2>Contact Us</h2>

                        If you have any questions or concerns about this Policy, please contact us at contact@saad.services.
                </div>
        </div>
    )
}

export default Privacy